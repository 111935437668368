export enum Labels {
  NETWORK_NAME = 'Network name',
  NETWORK_TYPE = 'Network type',
  TYPE = 'Type',
  BESU_IMAGE = 'Besu image',
  BESU_IMAGE_SECRET = 'Besu image secret',
  CONSENSUS = 'Consensus protocol',
  VALIDATORS_NUM = 'Number of validators',
  BOOTNODES_NUM = 'Number of bootnodes',
  RPC_NODE_GROUP = 'RPC node group',
  RPC_NODES_NUM = 'Number of RPC nodes',
  RPC_AUTH_STRATEGY = 'Authentication',
  RPC_CONFIG_URL = 'RPC configuration URL',
  RPC_ENDPOINT = 'RPC endpoint',
  RPC_WS_ENDPOINT = 'RPC WS endpoint',
  REQUESTED_CPU = 'Requested CPU',
  CPU_LIMIT = 'CPU limit',
  REQUESTED_MEMORY = 'Requested memory',
  MEMORY_LIMIT = 'Memory limit',
  STORAGE = 'Storage',
  BLOCK_PERIOD = 'Block period (seconds)',
  GAS_FREE_NETWORK = 'Gas-free network',
  VALIDATORS_AVAILABLE = 'Validators available',
  BOOTNODES_AVAILABLE = 'Bootnodes available',
  RPC_NODES_AVAILABLE = 'RPC nodes available',
  CHAIN_ID = 'Chain ID',
  NODE_NAME = 'Node name',
  NODE_GROUP_NAME = 'Node group name',
  NODE_TYPE = 'Node type',
  ADDRESS = 'Address',
  USERNAME = 'Username',
  PASSWORD = 'Password',
  VOTING_TYPE = 'Voting type',
  ACCOUNT_ADDRESS = 'Account address',
  UPDATE_CONFIGURATION_FILE = 'Upload configuration file',
  ENTER_MANUALLY = 'Enter manually',
  GENESIS_FILE = 'Genesis block file',
  HTTP_ENDPOINT = 'HTTP endpoint',
  WS_ENDPOINT = 'WS endpoint',
  CPU_PER_NODE = 'CPU per node',
  RAM_PER_NODE = 'RAM per node',
  STORAGE_PER_NODE = 'Storage per node',
  MAX_NODE_NUMBER = 'Max node number',
  API_GROUPS = 'API groups',
  METHODS_WITH_NO_AUTH = 'Methods with no auth',
  HOSTS_ALLOW_LIST = 'Hosts allow list',
  MAXIMUM_NODES_NUMBER = 'Maximum nodes number',
  RESOURCES = 'Resources',
  RESOURCES_PER_NODE = 'Resources per node',
  VALIDATOR_RESOURCES = 'Validator resources',
  BOOTNODE_RESOURCES = 'Bootnode resources',
  // RPC_RESOURCES = 'RPC node resources',
  HOSTS_ALLOWLIST = 'Hosts allowlist',
  ENTER_HOSTNAME = 'Enter hostname',
  SELECT_INPUT_METHOD = 'Select data input method',
  ANY = 'Any',
  CREATE = 'Create',
  // EXPLORER_IMAGE = 'Explorer image',
  MEMENTO_IMAGE = 'Memento image',
  ENABLE_BLOCK_EXPLORER = 'Enable Block explorer',
  ENABLE_MEMENTO = 'Enable Memento',
  BLOCK_EXPLORER = 'Block explorer',
  CONFIGURATIONS = 'Configurations',
  CONFIGURE_GENESIS_BLOCK = 'Configure genesis block',
  SUMMARY = 'Summary',
  DISABLED = 'Disabled',
  OPEN_ID = 'OpenID',
  CREDENTIALS = 'Credentials',
  EXTERNAL = 'External',
  PROVISIONED = 'Provisioned',
  HOST = 'Host',
  PORT = 'Port',
  DATABASE_NAME = 'Database name',
  POSTGRES_CONNECTION = 'PostgreSQL connection',
  REDIS_CONNECTION = 'Redis connection',
  AUTH_CREDENTIALS_NOTE = 'You can create RPC users from the Network details page',
}
